<template>
  <div class="login-page bg-wrapper">
    <div class="lan-wrapper">
      <ChangeLanguage />
    </div>
    <div class="wallet-box">
      <img src="../../assets/images/login/connect.png" class="wallet-img" />
      <div class="wallet-title">Connect Wallet</div>
    </div>
    <VanButton class="click-btn" @click="getConnect" :loading="btnLoading">{{
      common.link
    }}</VanButton>
    <div class="view-check" @click="handleToCertik">{{ common.auditReports }}</div>
    <div class="bottom">
      <p>{{ common.auditor }}</p>
      <img src="../../assets/images/login/certik.png" class="certik-img" @click="handleToCertik"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ChangeLanguage from '../../components/common/ChangeLanguage.vue'
import config from '../../config/app.config'
export default {
  components: { ChangeLanguage },
  data () {
    return {
      btnLoading: false
    }
  },
  computed: {
    ...mapState(['myAccount']),
    common () {
      return this.$t('common')
    }
  },
  methods: {
    ...mapActions(['changeAccount']),
    // 钱包监听
    listenWallet () {
      // 账号切换
      this.$web3.ethereum.on('accountsChanged', (accounts) => {
        console.log('accountsChanged')
        this.changeAccount(accounts[0])
      })
      // 断开链接
      this.$web3.ethereum.on('disconnect', res => {
        console.log('disconnect')
        this.changeAccount()
      })
      this.$web3.ethereum.on('chainChanged', (chainId) => {
        console.log(chainId)
        if (chainId !== config.chainId) {
          this.changeAccount()
        }
      })
    },
    async getAcount () {
      this.btnLoading = true
      const resp = await this.$web3.connectWallet()
      this.btnLoading = false
      if (!resp.success) {
        return this.$toast(resp.message)
      }
      this.acount = resp.result
      this.changeAccount(this.acount)
      // this.$router.replace('/systerm')
    },
    async getConnect () {
      await this.getAcount()
      if (this.acount) {
        this.$router.replace('/systerm')
      }
    },
    handleToCertik () {
      window.open('https://www.certik.com/projects/starfish-os')
    }
  },
  mounted () {
    // this.getAcount()
    // this.listenWallet()
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  padding: 0 60px;
  position: relative;
  text-align: center;
  .lan-wrapper {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 5;
  }
  .wallet-box {
    width: 100%;
    min-height: 395px;
    padding-top: 160px;
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // background: $linear-bg1;
      border-radius: 126px;
      opacity: 0.43;
      filter: blur(50px);
      z-index: 0;
    }
    .wallet-img {
      width: 186px;
      height: auto;
      margin-bottom: 15px;
      margin-left: 18px;
    }
    .wallet-title {
      font-weight: bold;
      font-size: $font-20;
    }
    * {
      position: relative;
      z-index: 1;
    }
  }
  .click-btn {
    width: 222px;
    height: 54px;
    background: $blue-bg;
    color: $base-color;
    border-radius: 27px;
    font-size: $font-20;
    font-weight: 600;
  }
  .view-check {
    color: $color-blue2;
    margin-top: 20px;
  }
  .bottom {
    margin: 100px auto auto;
    color: $color-gray1;
    :last-child {
      margin-top: 17px;
      color: $base-color;
      font-size: $font-20;
    }
    .certik-img {
      width: 125px;
      height: auto;
    }
  }
}
</style>
