 <template>
  <div class="language-wrapper" @click="changeLanguage">
    <i class="iconfont lang"></i>
    <span class="lang-box" translate="no">{{ langText[lang] }}</span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ChangeLanguage',
  data () {
    return {
      langText: {
        EN: 'English',
        ZH: '繁体中文'
      }
    }
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    ...mapActions(['changeLang']),
    changeLanguage () {
      const lang = this.lang === 'ZH' ? 'EN' : 'ZH'
      this.changeLang(lang)
    }

  },
  mounted () {

  }
}
</script>

 <style scoped lang="scss">
.language-wrapper {
  font-size: 12px;
  box-sizing: border-box;
  padding-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #d4cbc2;
  img {
    display: inline-block;
    width: 23px;
    height: 23px;
  }
  .lang {
    font-size: 24px;
    height: 23px;
  }
  .lang-box {
    min-width: 60px;
  }
}
</style>
